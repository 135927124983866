.zoneItem {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 5fr 1fr;
  height: 70px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  cursor: pointer;
}

.zoneItem:hover {
  background-color: #f5f4f4;
}

.highlighted {
  background-color: #d9d9ed;
}

.highlighted:hover {
  background-color: #c9c9dc;
}

.zoneItemCol {
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.zoneItemColSmall {
  text-align: center;
  z-index: 2;
}

.icon {
  width: 19px;
  height: 19px;
}

.menuItemIcon {
  margin: 0 12px;
  width: 13px;
}

.marginLeft {
  margin-left: 14px;
}

.marginLeftSmall {
  margin-left: 8px;
}

.marginBottom {
  margin-bottom: 5px;
}

.zoneAdditionalInfo {
  font-size: 12px;
}

.bold {
  font-weight: 700;
}
