.tableRow {
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 3fr 3fr 3fr 1fr;
  height: 70px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  cursor: pointer;
}

.tableRow:hover {
  background-color: rgb(239, 239, 239);
}

.tableCol {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.menuItemText {
  margin: 0 12px;
}

.menuItemIcon {
  margin: 0 12px;
}

.online {
  color: rgb(0, 205, 3);
}

.highlighted {
  background-color: rgb(224, 224, 224);
}
